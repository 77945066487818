import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditUniverseDto, BaseForm, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditUniverseDataService } from '../../services/data/audit-universe-data.service';

@Component({
  selector: 'app-audit-universe-item-form',
  templateUrl: './audit-universe-item-form.component.html',
  styleUrls: ['./audit-universe-item-form.component.scss'],
})
export class AuditUniverseItemFormComponent extends BaseForm<AuditUniverseDto> implements OnInit {
  contextVariables: any[] = [];
  auditUniverseStatuses = getEnumOptions(AuditUniverseDto.AuditUniverseStatusEnum);
  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditUniverseDataService
  ) {
    super(viewModeService, 'AUDIT_UNIVERSE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create' ? this.data : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      approver: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
