<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.approver.label' | translate: 'Approver'"
        [placeholder]="moduleFieldString + '.approver.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.approver"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <!-- <div class="col-12 md:col-6">
            <app-drop-down-input [label]="'Audit Universe Status'"
                [placeholder]="'Select Item'"
                [name]="'dropdown'"
                [optionLabel]="'label'"
                [optionValue]="'value'"
                [items]="auditUniverseStatuses"
                [optionValue]="'value'"
                [control]="formGroup?.controls?.auditUniverseStatus"
                [viewMode]="fieldViewMode"
                [badgeView]="true"></app-drop-down-input>

        </div> -->
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <ng-content></ng-content>
</form>
